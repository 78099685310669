html {
  color: black;
  font-size: 100%;
  background: black;
  font-optical-sizing: auto;
  font-family: "Inter", sans-serif;
}

body {
  background: white;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  transition: all 200ms ease-in-out;
}

a {
  color: var(--color-red);
}

h1, h2, h3, h4, h5 {
  color: #333;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.625rem;
}

h3 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
}

small {
  font-size: .875rem;
  line-height: 1.25rem;
}

sub {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

@media all and (min-width: 1024px) {
  h2 {
    font-size: 2.375rem;
    line-height: 3rem;
  }

  h3 {
    font-size: 1.375rem;
    line-height: 2rem;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  small {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  sub {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media all and (min-width: 1280px) {
  h2 {
    font-size: 2.625rem;
    line-height: 3.25rem;
  }
}
