.header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.5rem 1.5rem;
  justify-content: space-between;
  background: rgba(255, 255, 255, .4);
  transition: background 200ms ease-in-out;

  &:not(&--static) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__title {
    position: relative;
    font-size: 1.25rem;
    font-weight: 700;
    color: #333;

    a {
      color: currentColor;
      text-decoration: none;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      background: var(--color-blue);

      position: absolute;
      bottom: -6px;
      left: 0;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      background: var(--color-yellow);

      position: absolute;
      bottom: -10px;
      left: 0;
    }
  }

  &__toggle {
    border: 0;
    padding: 0;
    border: none;
    outline: none;
    display: flex;
    cursor: pointer;
    appearance: none;
    flex-flow: column wrap;
    background: transparent;

    &-line {
      width: 2rem;
      height: .25rem;
      margin: 3px 0;
      background: black;
    }
  }
}

@media all and (min-width: 768px) {
  .header {
    padding: 1.75rem 1.75rem;
  }
}

@media all and (min-width: 1024px) {
  .header {
    background: none;
    padding: 2rem 2rem;

    &__title {
      font-size: 1.25rem;
    }
  }
}

.navigation {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.1);
  color: white;

  transition: all 200ms ease-in-out;
  visibility: hidden;
  opacity: 0;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  &--open {
    visibility: visible;
    opacity: 1;
  }

  &__list {
    width: 19rem;
    height: 100%;
    background: black;

    transition: all 200ms ease-in-out;
    transform: translateX(100%);

    position: relative;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;

    ul {
      padding: 2rem;
    }
  }

  &--open &__list {
    transform: translateX(0);
  }

  &__item {
    font-size: 1.5rem;
    line-height: 1.875rem;

    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }

    a {
      padding-left: 1rem;
      display: inline-block;
      color: currentColor;
      text-decoration: none;
      border-left: .25rem solid currentColor;
    }
  }

  &__item--current a {
    color: var(--color-red);
  }

  &__close {
    padding: .5rem;
    border: none;
    outline: none;
    cursor: pointer;
    appearance: none;
    background: black;
    transform: translateX(-100%);

    position: absolute;
    top: 0;
    left: 0;

    img {
      width: 2rem;
      fill: white;
      height: auto;
      display: block;
    }
  }
}

@media all and (min-width: 640px) {
  .navigation {
    &__list {
      width: 22rem;
    }
  }
}

@media all and (min-width: 1280px) {
  .navigation {
    &__list ul {
      padding: 3rem;
    }

    &__close {
      padding: 1rem;
    }
  }
}
