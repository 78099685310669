.footer {
  color: white;
  background: black;
  text-align: center;
  padding-bottom: 2rem;
  position: relative;

  &__title {
    color: white;
    margin-bottom: 2rem;
  }

  &__contacts {
    font-size: 1.5rem;
    line-height: 2.125rem;

    li:not(:last-child) {
      margin-bottom: .5rem;
    }

    a {
      color: currentColor;
    }
  }
}
