@import "common/reset";
@import "common/colors";
@import "common/general";
@import "common/animations";

@import "../views/partials/navigation";
@import "../views/partials/hero";
@import "../views/partials/footer";




.content {
  width: 100%;

  &__inner {
    width: 100%;
    margin: 0 auto;
    padding: 3rem 1.5rem;

    &:not(&--semi) {
      max-width: 43.75rem;
    }

    &--semi {
      max-width: 80rem;
    }
  }
}

@media all and (min-width: 768px) {
  .content {
    &__inner {
      padding: 3rem 2rem;
    }
  }
}

@media all and (min-width: 1024px) {
  .content {
    &__inner {
      padding: 3.5rem 2rem;

      &:not(&--semi) {
        max-width: 47rem;
      }
    }
  }
}



.about {
  &__bio {
    text-align: center;
    margin-bottom: 3rem;
  }

  &__cta {
    margin: 0 auto;
    display: flex;
    border-radius: 6px;
    padding: 1rem 1rem;
    background: var(--color-red);
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    appearance: none;
    transition: opacity 200ms ease-in-out;

    color: white;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      opacity: .85;
    }

    img {
      width: 2rem;
      height: auto;
      display: block;
      margin-right: .75rem;
    }
  }
}

@media all and (min-width: 768px) {
  .about {
    &__cta {
      max-width: 16rem;
    }
  }
}

@media all and (min-width: 1024px) {
  .about {
    &__bio {
      margin-bottom: 4rem;
    }
  }
}




.resume {
  margin-bottom: 3rem;

  &__item {
    small {
      opacity: .5;
      display: block;
    }

    h3 {
      margin-bottom: .125rem;
    }

    sub {
      display: block;
      margin-bottom: .5rem;

      a {
        text-decoration: none;
      }
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}




.skills {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 2rem;

  &__skill {
    padding: 1rem;
    display: block;
    margin: 0 1rem 1rem 0;
    border: 2px solid black;
    box-shadow: rgba(0, 0, 0, 0.4) 6px 6px 0 0;
  }
}

@media all and (min-width: 1024px) {
  .skills {
    margin-bottom: 3rem;

    &__skill {
      font-size: 1.125rem;
    }
  }
}




.portfolio {
  &__part {
    &:not(:last-of-type) {
      margin-bottom: 4rem;
    }
  }

  &__intro {
    text-align: center;
    margin-bottom: 2rem;

    h2 {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2.125rem;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
  }

  &__item {
    height: 16rem;
    overflow: hidden;
    position: relative;
    border-radius: .25rem;

    &--award {
      height: auto;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: bottom left;
    }

    &--centered img {
      object-position: center;
    }
  }

  &__item-meta {
    width: 100%;
    height: auto;
    padding: 1rem 1rem;
    pointer-events: none;
    transform: translateY(100%);

    visibility: hidden;
    opacity: 0;

    background: var(--color-red);
    position: absolute;
    bottom: 0;
    left: 0;

    color: white;

    h3 {
      color: currentColor;
    }

    small {
      opacity: .7;
    }
  }

  &__awards {
    width: 100%;
    position: relative;
  }

  &__award {
    overflow: hidden;
    position: relative;
    border-radius: .25rem;
    border: 2px solid #d9d9d9;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__item:hover,
  &__award:hover {
    transform: scale(1.04);
    box-shadow: rgba(0, 0, 0, .2) 0 12px 20px;
  }

  &__item:hover &__item-meta,
  &__award:hover &__item-meta {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
}

@media all and (min-width: 640px) {
  .portfolio {
    &__item {
      height: 22rem;

      &--award {
        height: auto;
      }
    }
  }
}

@media all and (min-width: 768px) {
  .portfolio {
    &__intro {
      margin-bottom: 2.5rem;
    }

    &__items {
      gap: 1.75rem;
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      height: 18rem;

      &--award {
        height: auto;
      }
    }
  }
}

@media all and (min-width: 1024px) {
  .portfolio {
    padding-bottom: 2rem;

    &__intro {
      h2 {
        font-size: 1.875rem;
        line-height: 2.5rem;
      }
    }

    &__items {
      gap: 2rem;
    }

    &__item {
      height: 18rem;

      &--award {
        height: auto;
      }
    }
  }
}

@media all and (min-width: 1280px) {
  .portfolio {
    &__part {
      &:not(:last-of-type) {
        margin-bottom: 5rem;
      }
    }

    &__intro {
      margin-bottom: 3rem;

      h2 {
        font-size: 2.625rem;
        line-height: 3.25rem;
      }
    }

    &__items {
      grid-template-columns: repeat(3, 1fr);

      &--featured {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__items--featured &__item {
      height: 24rem;
    }

  }
}



.pswp__custom-caption {
  background: rgba(75, 150, 75, 0.75);
  font-size: 16px;
  color: #fff;
  width: calc(100% - 32px);
  max-width: 400px;
  padding: 2px 8px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
}

.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}

.hidden-caption-content {
  display: none;
}
