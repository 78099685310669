.hero {
  width: 100%;
  height: 60vh;
  overflow: hidden;
  background: white;
  position: relative;

  &--portfolio {
    height: 40vh;
  }

  &__image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    animation: image_unblur 500ms;
  }

  &::after {
    content: "";
    width: 100%;
    height: 5vh;
    background: url("../img/svg/divider.svg") no-repeat center;
    background-size: 100% 100%;
    position: absolute;

    bottom: 0;
    left: 0;
  }
}

@media all and (min-width: 640px) {
  .hero {
    height: 75vh;

    &--portfolio {
      height: 50vh;
    }
  }
}

@media all and (min-width: 768px) {
  .hero {
    height: 80vh;

    &--portfolio {
      height: 55vh;
    }
  }
}

@media all and (min-width: 1024px) {
  .hero {
    height: 90vh;

    &--portfolio {
      height: 60vh;
    }

    &::after {
      height: 7.5vh;
    }
  }
}

@media all and (min-width: 1280px) {
  .hero {
    &::after {
      height: 10vh;
    }
  }
}
